import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/messaging'
import 'firebase/analytics'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyAl-DCBP_MxrGxDKyqnzqswmacviScyblc',
  authDomain: 'neurocycle.firebaseapp.com',
  databaseURL: 'https://neurocycle-default-rtdb.firebaseio.com',
  projectId: 'neurocycle',
  storageBucket: 'neurocycle.appspot.com',
  messagingSenderId: '466588973497',
  appId: '1:466588973497:web:bdd1ca476143720d449e68',
  measurementId: 'G-Q37BRBWERC',
}


firebase.initializeApp(firebaseConfig)
const fbFirestore = firebase.firestore()
const fbAuth = firebase.auth()
const fbMessaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null

const fbCrashlytics = () => {
  return {
    recordError: (err) => {
      console.warn('crashlytics().recordError() is not implemented')
    },
    log: (message) => {
      console.log('crashlytics().log()', message)
    },
  }
}

const firestore = () => fbFirestore
firestore.FieldValue = firebase.firestore.FieldValue
const auth = () => fbAuth
const messaging = () => (firebase.messaging.isSupported() ? fbMessaging : null)
const crashlytics = () => fbCrashlytics()
const analytics = () => firebase.analytics()
const functions = () => firebase.functions()

export {
  firebase,
  firestore,
  auth,
  messaging,
  crashlytics,
  analytics,
  functions,
}

import { useState } from 'react'
import {
  ArrowBackIcon,
  Box,
  CloseIcon,
  KeyboardAvoidingView,
  Pressable,
  ScrollView,
  Text,
  Center,
  Container,
} from 'native-base'
import { AppForm } from 'components/Form'
import { DISPATCH, NOTIFICATION_STATUS } from 'state/consts'
import { ICycleStepProps } from 'state/interfaces'
import RNLocalize from 'react-native-localize'
import { activeReachConfig } from 'screens/Home/ActiveReach/formConfig'
import { saveReminder } from 'state/firebase/saveReminder'
import set from 'lodash/set'
import { firestore } from 'config/firebase'

interface ActiveReachProps extends ICycleStepProps {}

export function ActiveReach(props: ActiveReachProps) {
  const { colors } = props.useTheme
  const {
    state: { user },
  } = props.useAuth
  const [newReach, setNewReach] = useState('')
  const {
    state: cycleState,
    dispatch: { dispatch, markActiveReachAsCompleted },
  } = props.useCycles

  const initialValues = { reach: '', interval: null }
  const [error, setError] = useState(null)
  const config = set(activeReachConfig, 'initialValues', initialValues)
  const style = {
    justifyContent: 'flex-start',
    fontColor: colors.tangaroa[500],
    borderColor: colors.tangaroa[500],
    actionFooter: { textAlign: 'center' },
  }

  set(config, 'actions.submit.handle', (values: any) => {
    const lastTimezone = RNLocalize.getTimeZone()
    saveReminder({
      count: 0,
      interval: values.interval || null,
      messages: values.reach,
      notifiedAt: firestore.FieldValue.serverTimestamp(),
      status: NOTIFICATION_STATUS.NEW,
      title: values.interval,
      uid: user.uid,
      timezone: lastTimezone,
      cycle: cycleState.cycle,
      day: cycleState.day,
      track: cycleState.track,
      enabled: !!values.interval,
    })
    setNewReach(values)
    markActiveReachAsCompleted()
    if ([20, 21].includes(cycleState.day)) {
      dispatch({ type: DISPATCH.CYCLES.SHOW_VIDEO_MODAL })
    }
    onNext()
  })

  function onClose() {
    props.onClose()
  }

  function onBack() {
    props.onBack()
  }

  function onNext() {
    props.onNext()
  }

  return (
    <Box
      style={{
        minHeight: '100%',
      }}
      flexBasis="auto"
      bgColor={colors.puertorico[500]}
      flex={1}
    >
      <Center height="100%">
        <Container width="100%" height="100%" maxWidth={1024}>
          <KeyboardAvoidingView
            style={{
              height: '100%',
              width: '100%',
            }}
            behavior="position"
          >
            <Box
              safeArea
              px={5}
              py={5}
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              flexBasis="auto"
              _android={{ py: 8 }}
            >
              <Pressable onPress={onBack}>
                <ArrowBackIcon color="#FFFFFF" size={6} />
              </Pressable>
              <Text fontSize="4xl" variant="player">
                Set Active Reach Reminders
              </Text>
              <Pressable onPress={onClose}>
                <CloseIcon color="#FFFFFF" size={4} />
              </Pressable>
            </Box>
            <ScrollView
              style={{
                height: '100%',
                flexBasis: 'auto',
              }}
              bgColor={colors.white[500]}
              keyboardShouldPersistTaps="handled"
            >
              <Box
                safeArea
                flex={1}
                pb={6}
                px={6}
                _android={{ b: 20 }}
                flexBasis="auto"
              >
                <AppForm
                  config={config}
                  haveError={error}
                  style={style}
                  noKeyboardAvoidingView
                />
              </Box>
            </ScrollView>
          </KeyboardAvoidingView>
        </Container>
      </Center>
    </Box>
  )
}

/* eslint-disable camelcase */
import React, { createContext, useReducer, useMemo } from 'react'
import { DISPATCH } from 'state/consts'
import { profileHasSubscriptionOverride } from 'utils/profileHasSubscriptionOverride'
import { firestore, functions } from 'config/firebase'
import { Config } from 'config/config'
import { useAuth } from 'state/hooks/useAuth'
import Purchases, {
  PurchasesEntitlementInfo,
  PurchasesOffering,
  PurchasesOfferings,
  PurchasesPackage,
} from 'utils/purchases'
import { Platform } from 'react-native'
import { IUser } from 'state/interfaces'
import { TCycleState } from './CyclesProvider'
import { fetchCollectionByKey } from 'state/firebase/fetchCollectionByKey'

type TStore = 'stripe' | 'apple' | 'android' | 'promotional'

export type TPurchaseState = {
  purchases: any
  initialized: boolean
  premium: boolean
  expired: boolean
  availableProducts?: PurchasesOffering
  allProducts?: PurchasesOfferings
  purchaseHistory?: any
  store?: TStore
}
const initialState: TPurchaseState = {
  purchases: null,
  initialized: false,
  availableProducts: undefined,
  allProducts: undefined,
  expired: false,
  premium: false,
  purchaseHistory: undefined,
}
export const PurchaseContext = createContext(initialState)

function purchaseReducer(
  state: typeof initialState,
  action: any
): TPurchaseState {
  switch (action.type) {
    case DISPATCH.PURCHASE.SET_PURCHASE_HISTORY: {
      return {
        ...state,
        purchaseHistory: action.purchaseHistory,
      }
    }
    case DISPATCH.PURCHASE.SET_AVAILABLE_PRODUCTS: {
      return {
        ...state,
        availableProducts: action.availableProducts,
      }
    }
    case DISPATCH.PURCHASE.SET_ALL_PRODUCTS: {
      return {
        ...state,
        allProducts: action.allProducts,
      }
    }
    case DISPATCH.PURCHASE.SET_EXPIRED: {
      return {
        ...state,
        expired: action.expired,
      }
    }
    case DISPATCH.PURCHASE.SET_PREMIUM: {
      return {
        ...state,
        premium: action.premium,
        store: action.store,
      }
    }
    case DISPATCH.PURCHASE.SET_INITIALIZED: {
      return {
        ...state,
        initialized: action.initialized,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export function PurchaseProvider({ children }: React.ComponentProps<'div'>) {
  const [state, dispatch] = useReducer(purchaseReducer, initialState)
  const {
    dispatch: { setShowSubscriptionModal, setSubscriberBool },
    state: authState,
  } = useAuth()
  let purchaseHistoryVal: PurchasesEntitlementInfo | null = null

  Purchases.setDebugLogsEnabled(true)

  const initPurchases = async (
    user: any,
    profile: IUser,
    cycleState: TCycleState
  ) => {
    if (Platform.OS === 'ios') {
      await Purchases.configure({
        apiKey: Config.REVENUE_CAT_IOS_KEY!,
        appUserID: user.uid,
      })
    } else if (Platform.OS === 'android') {
      await Purchases.configure({
        apiKey: Config.REVENUE_CAT_ANDROID_KEY!,
        appUserID: user.uid,
      })
    } else {
      await Purchases.configure({
        apiKey: Config.REVENUE_CAT_WEB_KEY!,
        appUserID: user.uid,
      })
    }

    if (profile.email) {
      Purchases.setEmail(profile.email)
    }
    if (profile.firstName) {
      Purchases.setDisplayName(`${profile.firstName} ${profile.lastName}`)
    }

    const offeringsCurrent = await setAvailableProducts()

    const isSynced = profile?.subscriptionsSynced

    if (isSynced) {
      await syncPurchaseHistory(offeringsCurrent as any)
    } else if (profile && !isSynced) {
      // Tell Purchases to syncPurchases.
      // This will sync the user's receipt with RevenueCat.
      await firestore().collection('users').doc(user.uid).set(
        {
          uid: user?.uid,
          subscriptionsSynced: true,
          syncedAt: firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
      await Purchases.syncPurchases()
      await syncPurchaseHistory(offeringsCurrent as any)
    }
    dispatch({
      type: DISPATCH.PURCHASE.SET_INITIALIZED,
      initialized: true,
    })
  }

  const userTrialDays = (user: any) => {
    let trialDays = Config.TRIAL_DAYS
    if (user) {
      const creationTime = new Date(user?.metadata?.creationTime).getTime()
      const limitTime = new Date(Config.LIMIT_DATE).getTime()
      trialDays = creationTime < limitTime ? 6 : Config.TRIAL_DAYS
    }
    return trialDays
  }

  const checkIfShouldShowSubscriptionModal = (
    cycleState: TCycleState,
    user: any = null
  ) => {
    const trialDays = userTrialDays(user)
    if (cycleState.day >= trialDays && !state.premium) {
      setShowSubscriptionModal(true, false)
      setExpired(true)
      return true
    } else {
      setShowSubscriptionModal(false, false)
      setExpired(false)
      return false
    }
  }

  const setExpired = (val: boolean) => {
    dispatch({
      expired: val,
      type: DISPATCH.PURCHASE.SET_EXPIRED,
    })
  }

  const setPremium = (premium: boolean, store?: TStore) => {
    dispatch({
      premium,
      store,
      type: DISPATCH.PURCHASE.SET_PREMIUM,
    })
  }

  const setCurrentPlan = (
    product: PurchasesEntitlementInfo,
    offeringsCurrent?: PurchasesOffering
  ) => {
    const products = (offeringsCurrent || state.availableProducts)!
    if (product?.productIdentifier === products?.monthly?.product?.identifier) {
      setSubscriberBool({
        title: `1 month/${products.monthly.product.priceString}`,
        price: products.monthly.product.priceString,
        id: product.productIdentifier,
      })
    }
    if (
      product?.productIdentifier === products?.threeMonth?.product?.identifier
    ) {
      setSubscriberBool({
        title: `3 month/${products.threeMonth.product.priceString}`,
        price: products.threeMonth.product.priceString,
        id: product.productIdentifier,
      })
    }
    if (product?.productIdentifier === products?.annual?.product?.identifier) {
      setSubscriberBool({
        title: `12 month/${products.annual.product.priceString}`,
        price: products.annual.product.priceString,
        id: product.productIdentifier,
      })
    }
  }

  const setRestoredPlan = (identifier: string) => {
    const products = state.availableProducts
    if (identifier === products?.monthly?.product?.identifier) {
      setSubscriberBool({
        title: `1 month/${products.monthly.product.priceString}`,
        price: products.monthly.product.priceString,
        id: identifier,
      })
    }
    if (identifier === products?.threeMonth?.product?.identifier) {
      setSubscriberBool({
        title: `3 month/${products.threeMonth.product.priceString}`,
        price: products.threeMonth.product.priceString,
        id: identifier,
      })
    }
    if (identifier === products?.annual?.product?.identifier) {
      setSubscriberBool({
        title: `12 month/${products.annual.product.priceString}`,
        price: products.annual.product.priceString,
        id: identifier,
      })
    }
  }

  const syncPurchaseHistory = async (offeringsCurrent: PurchasesOffering) => {
    let isPremium = state.premium,
      isExpired = state.expired

    try {
      const customerInfo = await Purchases.getCustomerInfo()
      if (
        (customerInfo.entitlements.active &&
          Object.entries(customerInfo.entitlements.active).length) ||
        profileHasSubscriptionOverride(authState.profile)
      ) {
        isPremium = true
        purchaseHistoryVal = customerInfo?.entitlements?.active?.premium
        setPurchaseHistory(purchaseHistoryVal)
        setCurrentPlan(purchaseHistoryVal, offeringsCurrent)
      } else {
        isPremium = false
        purchaseHistoryVal = null
        setPurchaseHistory(null)
      }
      setPremium(
        isPremium,
        purchaseHistoryVal &&
          Boolean(purchaseHistoryVal.expires_date) &&
          customerInfo.subscriptions[
            Object.keys(customerInfo.subscriptions).slice(-1)[0]
          ].store
      )
    } catch (e) {
      console.log('Caught error', e)
      // Error fetching purchaser info
    }
    return { premium: isPremium, expired: isExpired }
  }

  const setPurchaseHistory = (value: PurchasesEntitlementInfo | null) => {
    dispatch({
      type: DISPATCH.PURCHASE.SET_PURCHASE_HISTORY,
      purchaseHistory: value,
    })
  }

  const setAvailableProds = (value: PurchasesOffering) => {
    dispatch({
      type: DISPATCH.PURCHASE.SET_AVAILABLE_PRODUCTS,
      availableProducts: value,
    })
  }

  const setAllProducts = (value: PurchasesOffering) => {
    dispatch({
      type: DISPATCH.PURCHASE.SET_ALL_PRODUCTS,
      allProducts: value,
    })
  }

  const setAvailableProducts = async () => {
    try {
      const offerings = await Purchases.getOfferings()
      if (offerings.all) {
        setAllProducts(offerings.all)
      }
      if (offerings.current !== null) {
        // Display current offering with offerings.current
        setAvailableProds(offerings.current)
        return offerings.current
      }
    } catch (e) {
      console.log(e)
    }
    return []
  }

  const makePurchase = async (product: PurchasesPackage) => {
    try {
      const purchaseMade = await Purchases.purchasePackage(product)
      if (
        Object.entries(purchaseMade.customerInfo.entitlements.active).length
      ) {
        // Unlock that great "pro" content
        setPremium(
          true,
          Platform.OS === 'web'
            ? 'stripe'
            : Platform.OS === 'ios'
            ? 'apple'
            : 'android'
        )
        setShowSubscriptionModal(false, false)
        setExpired(false)
        let purchaseHistoryVal: PurchasesEntitlementInfo =
          purchaseMade.customerInfo.entitlements?.active?.premium
        setPurchaseHistory(purchaseHistoryVal)
        setCurrentPlan(purchaseHistoryVal)
        return purchaseMade
      }
    } catch (e) {
      if (!(e as any).userCancelled) {
        console.log('Error -', e, product)
        console.log(JSON.stringify(e))
      }
    }
  }

  const restorePurchase = async () => {
    try {
      const restore = await Purchases.restorePurchases()
      const activeSubscription = restore?.activeSubscriptions || null
      if (!activeSubscription || activeSubscription?.length === 0) {
        alert('No active subscriptions')
        return
      }
      // set current plan if available
      setRestoredPlan(activeSubscription[0])
    } catch (e) {
      console.log('Restore error', e)
    }
  }

  const createReceipt = async () => {
    const stripeCheckoutId = window.localStorage.getItem('stripecheckid')
    if (typeof Purchases.createReceipt === 'function' && stripeCheckoutId) {
      Purchases.createReceipt(stripeCheckoutId)
    }
  }

  const cancelWebSubscription = async () => {
    await functions().httpsCallable('cancelSubscription')({
      email: authState.profile.email,
    })
  }

  const value = {
    dispatch: {
      checkIfShouldShowSubscriptionModal,
      dispatch,
      initPurchases,
      syncPurchaseHistory,
      setAvailableProducts,
      setAvailableProds,
      makePurchase,
      restorePurchase,
      userTrialDays,
      createReceipt,
      cancelWebSubscription,
    },
    state,
  }

  React.useEffect(() => {
    async function update() {
      // Update the user's profile
      await firestore().collection('users').doc(authState.user.uid).set(
        {
          premium: state.premium,
          expired: state.expired,
          syncedAt: firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
    }
    if (authState.user) {
      update()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.premium, state.expired])

  return (
    <PurchaseContext.Provider value={value}>
      {children}
    </PurchaseContext.Provider>
  )
}

import { firebase, firestore, messaging } from 'config/firebase'
import notifee, { EventType } from '@notifee/react-native'
import { useEffect, useMemo } from 'react'
import { routeNames } from 'state/routeNames'

import { FIREBASE_COLLECTIONS } from '../consts'
import { current } from 'immer'

export function useNotifications(user, navigate) {
  const onMessageReceived = useMemo(() => {
    return (remoteMessage) => {
      navigate(routeNames.NOTIFICATIONS_NAVIGATOR)
    }
  }, [navigate])
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      messaging().onMessage(
        (payload) => {
          console.log('Message received. ', payload)
          const { title, ...options } = payload.notification
          navigator.serviceWorker.register('firebase-messaging-sw.js')
          function showNotification() {
            Notification.requestPermission(function (result) {
              if (result === 'granted') {
                navigator.serviceWorker.ready.then(function (registration) {
                  registration.showNotification(payload.notification.title, {
                    body: payload.notification.body,
                    tag: payload.notification.tag,
                  })
                })
              }
            })
          }
          showNotification()
        },
        [onMessageReceived]
      )
    }
  })

  useEffect(() => {
    return notifee.onForegroundEvent(({ type, detail }) => {
      switch (type) {
        case EventType.PRESS:
          onMessageReceived(detail.notification)
          break
      }
    })
  }, [onMessageReceived])

  useEffect(() => {
    if (!user) {
      return
    }
    async function saveTokenToDatabase(token) {
      const userId = user.uid

      // Add the token to the users datastore
      if (userId) {
        await firestore()
          .collection(FIREBASE_COLLECTIONS.USERS)
          .doc(userId)
          .set(
            {
              uid: userId,
              tokens: firebase.firestore.FieldValue.arrayUnion(token),
            },
            { merge: true }
          )
      }
    }

    async function requestUserPermission() {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          messaging()
            .getToken({
              vapidKey:
                'BKQkjckzN8pSOfsZ1QOzrde1jB-2nowtTPAs19qYqgYKR0T_4cD3hKlZXE9p5rETE1yVzBfxr5Qoj8aLS5qIj58',
            })
            .then((currentToken) => {
              if (currentToken) {
                saveTokenToDatabase(currentToken)
              } else {
                // Show permission request UI
                console.log(
                  'No registration token available. Request permission to generate one.'
                )
                // ...
              }
            })
            .catch((err) => {
              console.log('An error occurred while retrieving token. ', err)
              // ...
            })
        } else {
          console.log('User has notification permissions disabled')
        }
      })
    }
    if (firebase.messaging.isSupported()) {
      requestUserPermission()
    }

    return firebase.messaging.isSupported()
      ? messaging().onTokenRefresh((token) => {
          saveTokenToDatabase(token)
        })
      : null
  }, [user])
}
